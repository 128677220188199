@import 'fonts';
@import 'functions';
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


.heading-h1-hidden {
    width: 1px;
    height: 1px;
    font-size: 1px;
    overflow: hidden;
}

textarea {
   resize: none;
}
