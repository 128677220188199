@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-Italic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 700;
    src: url("../fonts/FinoSansTitle-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-MediumItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 600;
    src: url("../fonts/FinoSansTitle-Medium.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-Italic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 500;
    src: url("../fonts/FinoSansTitle-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 400;
    src: url("../fonts/FinoSansTitle-Light.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 300;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-ThinItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 300;
    src: url("../fonts/FinoSansTitle-Thin.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 300;
    font-style: italic;
    src: url("../fonts/FinoSansTitle-UltraThinItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Fino Sans Title';
    font-weight: 300;
    src: url("../fonts/FinoSansTitle-UltraThin.otf") format("opentype");
}
